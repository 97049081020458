<template>
    <div>
        <h1>Stake DUST Earn ELMNT</h1>
        <br>
        <stake-dust :block_cards="current_cards"/>
        <br>
        <h1>Stake NFTs </h1>
        <h3>Stake Block<span>Cards</span> </h3>
        <stake-cards :block_cards="current_cards"/>
        
        <hr style="height: 3px; background-color: #333">

        <h1 style="text-decoration: underline;">Your Staked NFTs </h1>
        <div v-if="!is_approved_dust">
            <p> In order to UNSTAKE you must approve your DUST </p> 
            <base-button class="" @click="approve_dust" type="info">Approve Dust</base-button> 
        </div>
        
        <block-card-stake-box-mini :block_card_index_number="2" :title="'Fundamintals W 2022'"/>
        <block-card-stake-box-mini :block_card_index_number="1" :title="'Holiday 2021'"/>
        <block-card-stake-box-mini :block_card_index_number="0" :title="'BlockCards E1'"/>
        <!-- <manage-packs class="col-md-6"/> -->
        
        <!-- <manage-cards :block_cards="current_cards"/> -->
        <!-- <manage-packs class="col-md-6"/> -->

        
        
    </div>
</template>

<script>
import StakeCards from '../components/Inputs/StakeCards.vue'
import StakeDust from '../components/Inputs/StakeDust.vue'
const abis = require("../abis.json");
const globals = require("../globals.json");
const mainnet_contracts = require("../contracts_data.json"); 
const testnet_contracts = require("../test_contracts_data.json"); 
 import BlockCardStakeBoxMini from '../components/block_card_stake_box_mini.vue';


let contract_data; 

  if (globals.testnet){
    // using testnet
    // console.log("using testnet");
    contract_data = testnet_contracts
  }
  else{
    // using mainnet
    // console.log("using mainnet");
    contract_data = mainnet_contracts
  }


export default{

    components: {
        StakeCards,
        BlockCardStakeBoxMini,
        StakeDust
    },
    data(){
        return {
            current_packs: contract_data.block_packs,
            current_cards: contract_data.block_cards,
            is_approved_dust: false,
        }
    },
    mounted:  async function() {
        // let current_packs = contract_data.block_packs
        console.log("hi", contract_data.block_packs)
        this.check_approved_dust();
    },
    computed: {
        pack_data(){
            let object = contract_data.block_packs
            console.log*(object)
            return contract_data.block_packs
        }
    },
    methods: {
        async check_approved_dust(){
            let approved_or_not = await this.$store.dispatch('check_dust_approved',{})
            if (approved_or_not){
                console.log("HERE", approved_or_not)
                this.is_approved_dust = true
                
            }
            console.log("HERE", approved_or_not)
            
        },

        async approve_dust(){
            let result = await this.$store.dispatch('approve_dust',{})
            let approved = await this.check_approved_dust();
            
            
        }
            
    }





}
</script>
