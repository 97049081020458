<template>
  <div>
      <!-- <card class="space" style="width: 19rem; padding: 1rem"> -->
        <kinesis-container>
             <!-- <kinesis-element  :strength="15" 
                    type="depth"
                    >  -->
      <card >
        <div class="center">
         
        <!-- <div style="margin-bottom: -15%" class="">{{name}}</div> -->
        <div style="" class="">{{name}}</div>

        
        <!-- <img slot="image" class="card-img-top" src="img/eth_card.png" alt="Card image cap"/> -->
                <kinesis-element  :strength="0" 
                    type="depth"
                    > 
       
        <img :src=nft_img class="card-img-top"> 
        
        </kinesis-element >
        <p>Amount Owned: {{amount_owned}}</p>
       <div class="row justify-content-center" >
           
         <!-- <a :href=data_link target="blank"><p style="fontWeight: bold; color: purple">DATA</p></a> -->
                       <!-- <kinesis-element  :strength="20" 
                    type="depth"
                    >  -->
        <a :href=data_link target="2" class=""> <base-button type="info" class="button_left">Data</base-button></a>
         <!-- </kinesis-element >
                       <kinesis-element  :strength="20" 
                    type="depth"
                    >  -->
        <a :href=contract_link target="3" class=""> <base-button type="info" class="button_right">Contract</base-button></a>
</div>
<div class="row justify-content-center" >
        <a target="blank" class=""> <base-button type="danger" class="open_button" @click="activate_b_p_modal">Open & Burn</base-button></a>
        </div>
 <!-- </kinesis-element > -->
       
        
        </div>
      </card>
      <!-- </kinesis-element> -->
        </kinesis-container>
        <div v-if="amount_hook"></div> 
  </div>
</template>

<script>
import BaseButton from './BaseButton.vue';
import BaseNav from './BaseNav.vue';
import Card from './Cards/Card.vue';


let globals = require("../globals.json");


export default {
  components: { Card, BaseNav, BaseButton },
  name: 'TokenBalance',
  props: {
    name: { type: String, default: "NFT!"
    },
    randInt: { type: Number, default : 200
    },
    img_link:{ type: String, default : "https://picsum.photos/200/200"
    },
    img_scr:{ type: String, default : "https://picsum.photos/200/200"
    },
    nft_data:{ type: String, default : ""
    },
    contract:{ type: String, default : ""
    },
    number_of_block_packs: { type: String, default : ""
    },
    pack_index: { type: Number, default : -1,
    },
    cards_per_pack:{type:Number, default: 4},
    order_index: {type:Number, default: -1},
    inverse_index: {type:Number, default: -1},
  },
  data() {
      return {source: "https://picsum.photos/200/300",
              amount_owned: this.number_of_block_packs
      }
  },
  created: async function (){
    let block_pack_amount_hook = "block_pack_amount_hook" + String(this.pack_index)
    this.$store.state[block_pack_amount_hook] = 0
    console.log("created block pack viewer", "order index", this.order_index,this.pack_index)
    this.amount_owned = await this.$store.dispatch("get_total_block_pack_supply",{"index":this.pack_index})
  },
  computed: {
    nft_img(){
      return this.img_scr
    },
    nft_link(){
      return this.img_link
    },
    data_link(){
      return this.nft_data
    },
    contract_link(){
      if (globals.testnet){
        return "https:///testnet.bscscan.com/address/"+this.contract
      }
      else{
        return "https://bscscan.com/address/"+this.contract
      }
      
    },
    amount_hook(){
       let block_pack_amount_hook = "block_pack_amount_hook" + String(this.pack_index)
        if (this.$store.state[block_pack_amount_hook] != 0){
                let new_number = Number(this.amount_owned)
                new_number += -1
                this.amount_owned = String(new_number)
        }

      console.log(typeof this.amount_owned,"in hook")
      return this.$store.state[block_pack_amount_hook]
    }
    
  },
  methods:{
    click_open(){
      this.$emit("open")
    },    
    async activate_b_p_modal(item,nft){
      if (this.$store.state.open_spin == true){
        return
      }
        console.log(item, "WAT IS IT?")
          // this.modal_bc = true;
          this.$store.commit("change_state",{name: "current_cards_per_pack", new_value: this.cards_per_pack })
          this.$store.state.current_block_pack = this.name
          this.$store.commit('change_state',{"name":"current_block_pack_index","new_value": this.pack_index})
          console.log("what is this?:", this.inverse_index, this.pack_index)
          this.$store.commit('change_state',{"name":"current_block_pack_inverse_index","new_value": this.inverse_index})
          // this.$store.state.current_block_pack_index = this.pack_index
          this.$store.state.block_pack_open_modal = true
          

          console.log(this.modal_info, "WAT IS IT 2?")
          // this.price_on_modal = ((Number(await (this.$store.dispatch('get_price',{"nft_id":item.id}))) + 1e15) / 1e18 ).toFixed(4)
          // let result = await this.$store.dispatch('check_approved',{"token_data": item.id, "coin_id":0})
          // console.log("result ISS: ", result)
          // this.test_aproved = false
      }
  }

}
</script>
<style scoped>
.button_right{ 
  /* margin-bottom: -15px;  */
        margin-top:10px;
        margin-left:10%;
        margin-right:15%;
        }
.button_left{ 
  /* margin-bottom: -10px;  */
        margin-top:10px;
        /* margin-left:15%; */
        margin-right:15%;
        }
.open_button{
        margin-top:10px;
        
        /* margin-right:15%; */
        
}
</style>
