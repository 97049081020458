<template>
    <div>
        <br>
        <h1>Manage NFTs </h1>
        <h3>Manage Block<span>Cards</span> </h3>
        <manage-cards :block_cards="current_cards"/>
        <h3>Send Block<span>Packs</span> </h3>
        <manage-packs :block_packs="pack_data"/>
        <!-- <manage-packs class="col-md-6"/> -->
        
        <!-- <manage-cards :block_cards="current_cards"/> -->
        <!-- <manage-packs class="col-md-6"/> -->

        
        
    </div>
</template>

<script>
import ManagePacks from '../components/Inputs/ManagePacks.vue'
import ManageCards from '../components/Inputs/ManageCards.vue'
import {globalStore} from '../main.js'
const abis = require("../abis.json");
const globals = require("../globals.json");
const mainnet_contracts = require("../contracts_data.json"); 
const testnet_contracts = require("../test_contracts_data.json"); 

let abi_nft_1 = abis.nfts[0][1];
let abi_block_pack = abis.block_packs[0][1];
let abi_block_card = abis.block_cards[0][1];
// const  { nfts } = require("../contracts_data.json");

let current_nfts;
let current_block_packs ;
let current_block_cards;
let contract_data; 

  if (globals.testnet){
    // using testnet
    // console.log("using testnet");
    contract_data = testnet_contracts
  }
  else{
    // using mainnet
    // console.log("using mainnet");
    contract_data = mainnet_contracts
  }


export default{

    components: {
        ManagePacks,
        ManageCards
    },
    data(){
        return {
            current_packs: contract_data.block_packs,
            current_cards: contract_data.block_cards
        }
    },
    mounted:  function() {
        // let current_packs = contract_data.block_packs
        console.log("hi", contract_data.block_packs)
        
    },
    computed: {
        pack_data(){
            let object = contract_data.block_packs
            console.log*(object)
            return contract_data.block_packs
        }
    }





}
</script>
