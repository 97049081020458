<template>
    <div class="row">
        <card class="col-md-5" style="margin-right: 10px;">
            <form>
                <div class="form-row">
                    <base-input class="col-md-12" label="BlockCard" value="">
                            <select 
                            @change="switchIndex($event, $event.target.selectedIndex)"
                            id="inputState" class="form-control" v-model="model.selected">
                            <option disabled value="">Chose BlockCard Type</option>
                            <option v-for="(block_card, index) in block_cards" :key="index" style="color: black" >
                               <div>{{block_card.name}}</div>
                            </option>
                            </select>
                    </base-input>
                </div>
                
                <!-- <base-input @change="block_card_search($event, $event.target)" @keyup="block_card_search($event, $event.target)" v-if="card_selected" type="text" label="TokenId (the # of Block Card):" placeholder="#..." v-model="model.tokenId"/> -->


                <base-input  @keyup="block_card_search($event, $event.target)" v-if="card_selected" type="text" label="TokenId (the # of Block Card):" placeholder="#..." v-model="model.tokenId"/>
                <!-- <base-input type="text" label="How Many Packs to Send:" placeholder="amount" v-model="model.number"/> -->
                <!-- <base-input type="text" label="Reciever Address:" placeholder="0x..." v-model="model.address"/> -->
                <div class="form-row">
                    <p v-if="card_not_found">Card Does Not Exist (or burned) / Not found</p>
                    <p style="padding-left: 10px; margin-bottom: 20px" v-if="nft && own_card" >Connected Wallet owns this NFT</p>
                <!-- <base-button class="" @click="submitSend" type="info">Send</base-button> <p class="col-md-6">{{result_message}}</p> -->
                        <select v-if="nft && own_card" @change="switchAction($event, $event.target.selectedIndex)"
                            id="inputState" class="form-control" v-model="model.action_selected">
                            <option disabled value="">Select Action</option>
                            <option v-for="(action, index) in actions" :key="index" style="color: black" >
                               <div>{{action.description}}</div>
                            </option>
                        </select>
                        <p style="padding: 10px" v-if="nft && !own_card" >View Only - This wallet does not own this NFT</p>
                </div>
            <base-input v-if="model.actionIndex == 1 && nft && own_card" type="text" label="Reciever Address:" placeholder="0x..." v-model="model.address"/>
            <base-button v-if="model.actionIndex == 1" class="" @click="submitSend" type="info">Send</base-button> <p class="col-md-6">{{result_message}}</p>
            </form>
        </card>
        <div class="col-md-6" style="margin-left: 10px">
                <block-card-viewer v-if="nft" :extended="false" :img_link="'link'" :nft_data="nft[2]" :name="nft[3]" :contract="nft[4]" 
                      :contract_address="'link'" :card_info="nft[6]" :tokenId="nft[7]" :base_num="nft[8]" :is_new="nft[9]"
                      :index_number="0" />
                <card>
                <img v-if="!nft" src="img/blurr3.png"/>
                </card>
        </div>
    </div>
</template>

<script>

import {globalStore} from '../../main'
import BlockCardViewer from '../block_card_viewer.vue'

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}


export default{

    components: {
        BlockCardViewer
    },
    data(){
        return {
            model:{
            selected: "",
            action_selected: "",
            selectedIndex: -1,
            number: "",
            tokenId: "", 
            actionIndex: -1,
            address: ""
        },
        own_card: false,
        card_not_found: false,
        looking: false,
        block_pack_supply : "",
        result_message : "",
        nft: false,
        card_selected: false,
        actions: [{"description":"Send To"}],
        current_load: 0
        }
    },
    props: {
        block_cards: {}
    },
    created: function(){
        console.log("created manage packs")
    },
    mounted: async function(){
        console.log("mounted manage packs")
        // let result = await this.$store.dispatch("fetch_specific_block_card",{"number":0})
        // console.log(result, "wowza")
        // this.nft = result
    },
    watch: {
    //    'model.tokenId':{ 
    //     handler: async function (after, before) {
    //             // Changes detected. Do work...  

    //             // let number = Number(card_info.value)
    //             let number = Number(this.model.tokenId)
    //             let result = await this.$store.dispatch("fetch_specific_block_card",{"number": this.model.selectedIndex-1, "tokenId":number})  
    //             console.log("leaving",this.model.tokenId)
    //             this.nft = result.card_info
    //             // this.nft = result.card_info
    //         if (result == false){
    //             this.card_not_found = true
    //             console.log("not there")
               
    //             return
    //         }
            
    //         this.card_not_found = false
    //         console.log(result, "wowza")

    //         if (result.is_owned){
    //             this.own_card = true
    //         }
    //         else{
    //             this.own_card = false
    //         }
                
    //         },
    //         deep: true
    //    }
        },
    methods: {
        async submitSend(){
            console.log("sending blockcard",this.model.selected,this.model.tokenId,this.model.selectedIndex, this.model.address, this.model.actionIndex)
            // return
            console.log(window.ethereum.isMetaMask)
            // gotta move index down by 1 to get proper block pack index her 
            let index = Number(this.model.selectedIndex)
            let tokenId = Number(this.model.tokenId)
            let to_address = this.model.address
            let result = await this.$store.dispatch("send_block_card",{"index":index - 1, "tokenId": tokenId, "to_address":to_address })
            if (result == false){
                return
            }
            this.nft = false
            this.model.tokenId = ""
            // this.model.selected = ""
            // this.model.actionIndex = -1
            this.model.address = ""
            // this.model.address = ""
            // this.model.number = ""
            // this.block_pack_supply = ""

            // this.$store.dispatch("send_block_pack",{"index":index - 1, "amount": amount, "to_address":to_address })
            // .then((result)=>{console.log("PROMO?>>",result)})
            // .then((result)=>{ console.log("PROMO?>>",result)})
            //  .then((result)=>{ console.log("PROMO?>>",result)})
            // if (result.status = true)
            // console.log("PROMO?>>",result)
            // let new_sup = await this.get_block_pack_supply(index-1)

        },
        switchIndex: async function(event, selectedIndex) {
            this.card_selected = true
            console.log("switched")
            console.log(selectedIndex)
            this.model.selectedIndex = selectedIndex;
            // this.block_pack_supply = await this.get_block_pack_supply(selectedIndex-1)
            this.nft = false
            this.model.tokenId = ""
            // this.model.selected = ""
            // this.model.actionIndex = -1
            this.model.address = ""
        },
        switchAction: async function(event,selectedIndex){
            this.model.actionIndex = selectedIndex
            console.log(this.model.actionIndex )
        },
        block_card_search: async function(event,card_info){
            // alert("hi")
            this.nft = false
            this.current_load +=1
            let status = this.current_load
            if (card_info.value == ""){
                console.log("blank")
                return
            }
            console.log("search",card_info.value)
            console.log(card_info,event)
            let number = Number(card_info.value)
            await timeout(1500)
            if (status != this.current_load){
                console.log("oops")
                return
            }
            let result = await this.$store.dispatch("fetch_specific_block_card",{"number": this.model.selectedIndex-1, "tokenId":number})
            
            this.nft = result.card_info
            if (result == false){
                this.card_not_found = true
                console.log("not there")
                return
            }
            
            this.card_not_found = false
            console.log(result, "wowza")

            if (result.is_owned){
                this.own_card = true
            }
            else{
                this.own_card = false
            }
            
            

        }
    }
    



}
</script>
