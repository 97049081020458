<template>
    <div >
      <br> <br>
        <!-- Hello -->
            <h2>How to Search for Bounties</h2>
        <div class="video-container">
        <iframe width="50%" height="50%" src="https://www.youtube.com/embed/YcTbpGJHDQg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <br>
     <bounty-box :bounty_index_number="0" :title="'Bouties V1'"/>
     </div>
</template>

<script>
import {globalStore} from '../main.js'
import BountyBox from '../components/bounty_box.vue';
const abis = require("../abis.json");
const globals = require("../globals.json");
const mainnet_contracts = require("../contracts_data.json"); 
const testnet_contracts = require("../test_contracts_data.json"); 

let abi_nft_1 = abis.nfts[0][1];
let abi_block_pack = abis.block_packs[0][1];
let abi_block_card = abis.block_cards[0][1];
// const  { nfts } = require("../contracts_data.json");


let contract_data; 

  if (globals.testnet){
    // using testnet
    // console.log("using testnet");
    contract_data = testnet_contracts
  }
  else{
    // using mainnet
    // console.log("using mainnet");
    contract_data = mainnet_contracts
  }


export default{

    components: {
        BountyBox
    },
    data(){
        return {
        }
    },
    mounted:  function() {
        // let current_packs = contract_data.block_packs
        console.log("Bounties")

        console.log("bounty info")
        
    },
    computed: {
        
    }

}
</script>
<style scoped>
.responsive-youtube
{overflow:hidden; padding-bottom:56.25%; position:relative; height:0;}
.responsive-youtube iframe
{left:0; top:0; height:100%; width:100%; position:absolute;}

.video-container {
	position:relative;
	padding-bottom:56.25%;
	padding-top:30px;
	height:0;
	overflow:hidden;
}

.video-container iframe, .video-container object, .video-container embed {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
}
</style>
