<template>
    <div>
        <nft-box/>
    </div>
</template>

<script>
 import NftBox from '../components/NftBox_01.vue';
  import {globalStore} from '../main.js'

export default{
// currently this component is not used
    components: {
        NftBox
    }
    


}
</script>
