<template>
    <div class="row">
        <card class="col-md-5" style="margin-right: 10px;">
            <form>
                <div class="form-row">
                    <base-input class="col-md-12" label="BlockCard" value="">
                            <select 
                            @change="switchIndex($event, $event.target.selectedIndex)"
                            id="inputState" class="form-control" v-model="model.selected">
                            <option disabled value="">Chose BlockCard Type to Stake</option>
                            <option v-for="(block_card, index) in block_cards" :key="index" style="color: black" >
                               <div>{{block_card.name}}</div>
                            </option>
                            </select>
                    </base-input>
                </div>
                
                <!-- <base-input @change="block_card_search($event, $event.target)" @keyup="block_card_search($event, $event.target)" v-if="card_selected" type="text" label="TokenId (the # of Block Card):" placeholder="#..." v-model="model.tokenId"/> -->

                <base-input  @keyup="block_card_search($event, $event.target)" v-if="card_selected" type="text" label="TokenId (the # of Block Card):" placeholder="#..." v-model="model.tokenId"/>
                <!-- <base-input type="text" label="How Many Packs to Send:" placeholder="amount" v-model="model.number"/> -->
                <!-- <base-input type="text" label="Reciever Address:" placeholder="0x..." v-model="model.address"/> -->
                <div class="">
                    <p v-if="card_not_found">Card Does Not Exist (or burned) / Not found</p>
                    <p style="padding: 10px" v-if="nft && !own_card" >This wallet does not own this NFT</p>
                    <!-- <p style="padding: 10px; color: #67e681; font-weight: bold" v-if="nft && !own_card && !nft_listed" >Not Currently Listed</p> -->
                    <!-- <p style="padding: 10px"> <a v-if="nft && !own_card && !nft_listed" href="https://discord.gg/5e7FQS6vd2" target="_blank">Ask about a Trade</a> </p> -->
                    <!-- <p style="padding: 10px; color: #67e681; font-weight: bold" v-if="nft && !own_card && nft_listed" >BUT it is listed for trade!</p> -->
                    <!-- <p style="padding: 10px" v-if="nft && !own_card && nft_listed" >Price {{list_price}} BNB</p> -->
                    <p style="padding-left: 10px; margin-bottom: 20px; color: #67e681;" v-if="nft && own_card" >Connected Wallet owns this NFT:</p>

                    <div v-if="nft && nft_listed && !own_card">
                        <base-button class="" @click="submitTrade" type="info">Trade</base-button> <p class="col-md-6">{{result_message}}</p>
                    </div>
                    <div>
                        <!-- <p style=" padding-left: 40px; color: #67e681; font-weight: bold" 
                        v-if="nft && own_card && approved && nft_listed" >Currently Listed for: {{list_price}} BNB </p> -->
                        <!-- <p style=" padding-left: 40px; color: #67e681; font-weight: bold" v-if="nft && own_card && approved"> Stake</p> -->
                        <hr style="height: 3px; background-color: #333" v-if="!approved && show_approve">
                        <div v-if="!approved && show_approve">
                            <p> If you wish to Stake an NFT -> </p>
                            <p> Please give permission to the Staking contract to transfer your NFTs </p>
                        <base-button  class="" @click="approveCards()" type="info">Approve</base-button>
                        </div>
                    </div>
                <!-- <base-button class="" @click="submitList" type="info">Send</base-button> <p class="col-md-6">{{result_message}}</p> -->
                        <!-- <select v-if="nft && own_card" @change="switchAction($event, $event.target.selectedIndex)"
                            id="inputState" class="form-control" v-model="model.action_selected">
                            <option disabled value="">Select Action</option>
                            <option v-for="(action, index) in actions" :key="index" style="color: black" >
                               <div>{{action.description}}</div>
                            </option>
                        </select> -->
                        <br>
                        <div v-if="nft && own_card && approved">
                            <p>You will recieve approximately {{dust_calculated}}</p>
                        <base-button class="" @click="submitStake" type="info">Stake!</base-button> <p class="col-md-6">{{result_message}}</p>
                        </div>

                        
                        
                </div>
            <!-- <base-input v-if="model.actionIndex == 1 && nft && own_card" type="text" label="Reciever Address:" placeholder="0x..." v-model="model.address"/> -->
            
            
        </form>
        </card>
        <div class="col-md-6" style="margin-left: 10px">
                <block-card-viewer v-if="nft" :extended="false" :img_link="'link'" :nft_data="nft[2]" :name="nft[3]" :contract="nft[4]" 
                      :contract_address="'link'" :card_info="nft[6]" :tokenId="nft[7]" :base_num="nft[8]" :is_new="nft[9]"
                      :index_number="0" />
                <card>
                <img v-if="!nft" src="img/blurr3.png"/>
                </card>
        </div>
    </div>
</template>

<script>

import {globalStore} from '../../main'
import BlockCardViewer from '../block_card_viewer.vue'

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}


export default{

    components: {
        BlockCardViewer
    },
    data(){
        return {
            model:{
            selected: "",
            action_selected: "",
            selectedIndex: -1,
            number: "",
            tokenId: "", 
            actionIndex: -1,
            address: "",
            trade_value: "",
            trade_address: "0x0000000000000000000000000000000000000000"
        },
        own_card: false,
        card_not_found: false,
        looking: false,
        block_pack_supply : "",
        result_message : "",
        nft: false,
        card_selected: false,
        actions: [{"description":"Send To"}],
        current_load: 0,
        amount_needed: false,
        approved: false,
        show_approve: false,
        nft_listed: false,
        list_price: "",
        designated: false,
        designated_address: "",
        dust_calculated: 0,
        }
    },
    props: {
        block_cards: {}
    },
    created: function(){
        console.log("created trade cards")
    },
    mounted: async function(){
        console.log("mounted trade cards")
    },
    methods: {
        async submitStake(){
            console.log("staking blockcard",
            this.model.selected,this.model.tokenId, this.model.selectedIndex,  this.model.actionIndex,
            this.model.trade_address, this.model.trade_value)
            // if (this.model.trade_value == ""){
            //     this.amount_needed = true
            //     return
            // }
            console.log(window.ethereum.isMetaMask)
            // gotta move index down by 1 to get proper block pack index her 
            let index = Number(this.model.selectedIndex)
            let tokenId = Number(this.model.tokenId)
            
            let trade_address = this.model.trade_address
            let trade_value = Number(this.model.trade_value)
            try{
                // invers index
                console.log("index",index)
            let result = await this.$store.dispatch("stake_block_card",{
                "index":index - 1, "tokenId": tokenId })
            if (result == false){
                return
            }
            let add_cards_result = await this.$store.dispatch("after_stake_add_cards_to_array",
            {"index":index - 1, "num_cards": 1})
            this.nft = false
            this.model.tokenId = ""
            this.model.address = ""
            this.model.trade_address = "0x0000000000000000000000000000000000000000"
            this.model.trade_value = ""
            }
            catch(err){
                console.log(err)
                return
            }


        },

        async calculateDust(){
            let index = Number(this.model.selectedIndex)
            let tokenId = Number(this.model.tokenId)
            let result = await this.$store.dispatch("calculate_dust",{
                "index":index - 1, "tokenId": tokenId, "not_yet_staked": true })

            this.dust_calculated = result.toFixed(0);
            console.log("result should be:", result)
        },

        
        async submitTrade(){
            console.log("trading blockcard",
            this.model.selected,this.model.tokenId, this.model.selectedIndex,  this.model.actionIndex,
            this.model.trade_address, this.model.trade_value)
            console.log(window.ethereum.isMetaMask)
            // gotta move index down by 1 to get proper block pack index her 
            let index = Number(this.model.selectedIndex)
            let tokenId = Number(this.model.tokenId)
            
            console.log("trade_block_card", index - 1, "tokenId", tokenId,
                "trade_value", this.list_price)
            let result = await this.$store.dispatch("trade_block_card",{
                "index":index - 1, "tokenId": tokenId,
                "trade_value" : this.list_price })
            if (result == false){
                return
            }
            this.nft = false
            this.model.tokenId = ""
            this.model.address = ""
            this.model.trade_address = "0x0000000000000000000000000000000000000000"
            this.model.trade_value = ""


        },
        switchIndex: async function(event, selectedIndex) {
            this.card_selected = true
            console.log("switched")
            console.log(selectedIndex)
            this.model.selectedIndex = selectedIndex;
            // this.block_pack_supply = await this.get_block_pack_supply(selectedIndex-1)
            this.nft = false
            this.model.tokenId = ""
            // this.model.selected = ""
            // this.model.actionIndex = -1
            this.model.address = ""
            let is_approved = await this.isApprovedFor(selectedIndex)
            this.show_approve = true
        },
        switchAction: async function(event,selectedIndex){
            this.model.actionIndex = selectedIndex
            console.log(this.model.actionIndex )
        },
        resetAmmountNeeded: function(){
            this.amount_needed = false
        },
        approveCards: async function(){
            console.log("approving blockcards",
            this.model.selected,this.model.tokenId, this.model.selectedIndex,  this.model.actionIndex,
            this.model.trade_address, this.model.trade_value)
            
            console.log(window.ethereum.isMetaMask)
            // gotta move index down by 1 to get proper block pack index her 
            let index = Number(this.model.selectedIndex)
            let tokenId = Number(this.model.tokenId)
            
            let trade_address = this.model.trade_address
            let trade_value = Number(this.model.trade_value)

            let result = await this.$store.dispatch("approve_nft",{
                "index":index - 1, "approve_type": "locker"})
            if (result == false){
                return
            }

            let is_approved = await this.isApprovedFor(this.model.selectedIndex)

        },
        isApprovedFor: async function(selectedIndex){
            let result = await this.$store.dispatch("is_approved_for_staking",{
                "index":selectedIndex - 1})
            if (result == false){
                console.log("got approval? 2")
                this.approved = false
                return false
            }
            this.approved = true
            return true
            
        },
        
        block_card_search: async function(event,card_info){
            // alert("hi")
            this.nft = false
            this.current_load +=1
            let status = this.current_load
            if (card_info.value == ""){
                console.log("blank")
                return
            }
            console.log("search",card_info.value)
            console.log(card_info,event)
            let number = Number(card_info.value)
            await timeout(1500)
            if (status != this.current_load){
                console.log("oops")
                return
            }
            let result = await this.$store.dispatch("fetch_specific_block_card",{"number": this.model.selectedIndex-1, "tokenId":number})

            // let trade_result = await this.$store.dispatch("trade_info",{"number": this.model.selectedIndex-1, "tokenId":number})

            // console.log("trade_result",trade_result)

            // if (trade_result.account_eligible && trade_result.trade_result_listed && trade_result.approval_result){
            //     this.nft_listed = true
            //     let temp_price =  (((Number(trade_result.trade_result_price)))/ 1e18).toFixed(18) 
            //     this.list_price = (Number(temp_price) + Number(temp_price * 0.001)).toFixed(8)
            // }
            // else if(!trade_result.account_eligible && result.is_owned && trade_result.trade_result_listed && trade_result.approval_result){
            //     this.nft_listed = true
            //     let temp_price =  (((Number(trade_result.trade_result_price)))/ 1e18).toFixed(18) 
            //     this.list_price = (Number(temp_price) + Number(temp_price * 0.001)).toFixed(8)

            // }
            // else{
            //     console.log("getting elsed")
            //     this.nft_listed = false
            //     this.list_price = ""
            // }
            

            this.nft = result.card_info
            if (result == false){
                this.card_not_found = true
                console.log("not there")
                return
            }
            
            this.card_not_found = false
            console.log(result, "wowza")

            if (result.is_owned){
                this.own_card = true
            }
            else{
                this.own_card = false
            }
            await this.calculateDust();
            
            

        }
    }
    



}
</script>
