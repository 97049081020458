<template>
    <div>
        <kinesis-container>
             <kinesis-element  :strength="10" 
                    type="depth"> 
        <card class="">
        Hello Test 1 {{tester}}
        </card>
             </kinesis-element>
        </kinesis-container>

<div class="self-building-square-spinner">
  <div class="square"></div>
  <div class="square"></div>
  <div class="square"></div>
  <div class="square clear"></div>
  <div class="square"></div>
  <div class="square"></div>
  <div class="square clear"></div>
  <div class="square"></div>
  <div class="square"></div>
</div>
    </div>
</template>

<script>
import Card from '../components/Cards/Card.vue'
import Web3 from 'web3';
const abis = require("../abis.json");

let current_block_cards;
const mainnet_contracts = require("../contracts_data.json"); 
const testnet_contracts = require("../test_contracts_data.json"); 
const globals = require("../globals.json");
if (globals.testnet){

    current_block_cards = testnet_contracts.block_cards
    // console.log("current_block_cards", current_block_cards)
  }
  else{
    current_block_cards = mainnet_contracts.block_cards
    // console.log("current_block_cards", current_block_cards)
  }


let abi_block_card = abis.block_cards[0][1];

const fetch_nft = async (i,j,account) => {
          //
          let web3_1 = new Web3(window.ethereum);
          let contract_token_1 = new web3_1.eth.Contract(abi_block_card,current_block_cards[i].address)
          console.log(account,"THE account")
          let current_nft
          let owners_balance
          let card_info 
          try{
                current_nft = await contract_token_1.methods.tokenOfOwnerByIndex(account,j).call({from: account})
                owners_balance = await contract_token_1.methods.balanceOf(account).call({from: account})
                card_info = await contract_token_1.methods.card_info(current_nft).call({from: account})
                console.log("current token",current_nft)
                console.log("card info",card_info)
          }
          catch(err){
              // console.log(err)
              return false
          }
          if (current_nft){
            let nft_uri = await contract_token_1.methods.tokenURI(current_nft).call()
            console.log("token URI fetched: ", nft_uri);
            let just_hash = nft_uri.slice(7)
            just_hash = "QmS68e8vTsy3oyXgmFzvWp2f67odtq2Bv64JUnAJwoczVA"
            let link = "https://gateway.pinata.cloud/ipfs/"+ just_hash
            // let link_result = await fetchJsonGetImage(link)
            // console.log("link_result: ", link_result)
            return (['link_result.link_image','current_block_card_images[i]',link,current_block_cards[i].name,current_block_cards[i].address,
            owners_balance,card_info,current_nft,i]);
          }
          
}

export default{
data (){
  return {
    tester: "hi"
  }
},
created: async function (){
  console.log("hello world from testsandbox")
      let web3_1 = new Web3(window.ethereum);
      let accounts = await web3_1.eth.getAccounts()
      let account = accounts[0]
  let variable;
  this.tester = "whatsup"

  fetch_nft(0,0,account).then((result) => {this.tester = result})
}
    

}
</script>
<style scoped>
.self-building-square-spinner, .self-building-square-spinner * {
      box-sizing: border-box;
    }

    .self-building-square-spinner {
      height: 40px;
      width: 40px;
      top: calc( -10px * 2 / 3);
    }

    .self-building-square-spinner .square {
      height: 10px;
      width: 10px;
      top: calc( -10px * 2 / 3);
      margin-right: calc(10px / 3);
      margin-top: calc(10px / 3);
      background: #e70909;
      float: left;
      position:relative;
      opacity: 0;
      animation: self-building-square-spinner 6s infinite;
    }

    .self-building-square-spinner .square:nth-child(1) {
      animation-delay: calc(300ms * 6);
    }

    .self-building-square-spinner .square:nth-child(2) {
      animation-delay: calc(300ms * 7);
    }

    .self-building-square-spinner .square:nth-child(3) {
      animation-delay: calc(300ms * 8);
    }

    .self-building-square-spinner .square:nth-child(4) {
      animation-delay: calc(300ms * 3);
    }

    .self-building-square-spinner .square:nth-child(5) {
      animation-delay: calc(300ms * 4);
    }

    .self-building-square-spinner .square:nth-child(6) {
      animation-delay: calc(300ms * 5);
    }

    .self-building-square-spinner .square:nth-child(7) {
      animation-delay: calc(300ms * 0);
    }

    .self-building-square-spinner .square:nth-child(8) {
      animation-delay: calc(300ms * 1);
    }

    .self-building-square-spinner .square:nth-child(9) {
      animation-delay: calc(300ms * 2);
    }

    .self-building-square-spinner .clear{
      clear: both;
    }

    @keyframes self-building-square-spinner {
      0% {
        opacity: 0;
      }
      5% {
        opacity: 1;
        top: 0;
      }
      50.9% {
        opacity: 1;
        top: 0;
      }
      55.9% {
        opacity: 0;
        top: inherit;
      }
    }

.semipolar-spinner, .semipolar-spinner * {
      box-sizing: border-box;
    }

    .semipolar-spinner {
      height: 65px;
      width: 65px;
      position: relative;
    }

    .semipolar-spinner .ring {
      border-radius: 50%;
      position: absolute;
      border: calc(65px * 0.05) solid transparent;
      border-top-color: #000000;
      border-left-color: #ff1d5e;
      animation: semipolar-spinner-animation 2s infinite;
    }

    .semipolar-spinner .ring:nth-child(1) {
      height: calc(65px - 65px * 0.2 * 0);
      width: calc(65px - 65px * 0.2 * 0);
      top: calc(65px * 0.1 * 0);
      left: calc(65px * 0.1 * 0);
      animation-delay: calc(2000ms * 0.1 * 4);
      z-index: 5;
    }

    .semipolar-spinner .ring:nth-child(2) {
      height: calc(65px - 65px * 0.2 * 1);
      width: calc(65px - 65px * 0.2 * 1);
      top: calc(65px * 0.1 * 1);
      left: calc(65px * 0.1 * 1);
      animation-delay: calc(2000ms * 0.1 * 3);
      z-index: 4;
    }

    .semipolar-spinner .ring:nth-child(3) {
      height: calc(65px - 65px * 0.2 * 2);
      width: calc(65px - 65px * 0.2 * 2);
      top: calc(65px * 0.1 * 2);
      left: calc(65px * 0.1 * 2);
      animation-delay: calc(2000ms * 0.1 * 2);
      z-index: 3;
    }

    .semipolar-spinner .ring:nth-child(4) {
      height: calc(65px - 65px * 0.2 * 3);
      width: calc(65px - 65px * 0.2 * 3);
      top: calc(65px * 0.1 * 3);
      left: calc(65px * 0.1 * 3);
      animation-delay: calc(2000ms * 0.1 * 1);
      z-index: 2;
    }

    .semipolar-spinner .ring:nth-child(5) {
      height: calc(65px - 65px * 0.2 * 4);
      width: calc(65px - 65px * 0.2 * 4);
      top: calc(65px * 0.1 * 4);
      left: calc(65px * 0.1 * 4);
      animation-delay: calc(2000ms * 0.1 * 0);
      z-index: 1;
    }

    @keyframes semipolar-spinner-animation {
      50% {
        transform: rotate(360deg) scale(0.7);
      }
    }
</style>

