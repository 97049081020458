<template>
  <div>
    
    <card>
      <div>
      <h4 class="lined_up">{{bounty_info.name}}</h4>
      <p v-if="reward_ammount" class="lined_up" style="font-size: 20px; color: gold; margin-left: 20px">Reward: {{reward_ammount}} BNB</p>
      </div>
      <p style="font-size: 10px">Bounty Address: {{bounty_info.address}}</p>
      <div class="row">
        <div class="col-md-6" >
          <div class="row">
          <div v-for="number in bounty_info.num_cards" :key="number" class="col-md-6" style="margin-top: 20px"> 

            <h4 style="font-weight: bold"> Card {{number}} requires</h4>
            <!-- <p>{{cards_infos[number-1]}}</p> -->
            <p><span style="font-weight: bold; color: green">Pack Run: </span>
              {{bounty_info.cards[number-1].pack[0]}} </p>
            <p v-if="type_requirement(number-1)">
              <span style="font-weight: bold; color: green">Crypto Type:</span>  {{type_requirement(number-1)}} </p>
            <p v-if="ultra_requirement(number-1)">
              <span style="font-weight: bold; color: green">Ultra Type:</span> {{ultra_requirement(number-1)}} </p> 
            <img v-if="ultra_requirement(number-1)" :src="get_background(number-1)" style="width: 50%">
            <p v-if="rare_requirement(number-1)" >
              <span style="font-weight: bold; color: green">Rare?:</span>
                 {{rare_requirement(number-1)}}</p>
            <p v-if="border_requirement(number-1)" >
              <span style="font-weight: bold; color: green">Border Type: </span>
                {{border_requirement(number-1)}} </p>
            <img v-if="border_requirement(number-1)" :src="get_border(number-1)" style="width: 50%">

          </div>
          </div>
        </div>
        <div v-if="!bounty_open" class="col-md-6">
          <p>This Bounty is paused or has been claimed.</p>
        </div>
        <div v-if="bounty_open" class="col-md-6">
        <div v-for="number in bounty_info.num_cards" :key="number">
        <base-input class=" lined_up" :label="getlabel(number)"  value="">
            <select 
            @change="switchIndex($event, $event.target.selectedIndex, number-1)"
            id="inputState" class="lined_up form-control" v-model="model[number-1].selected">
            <option disabled value="">Chose BlockCard Type</option>
            <option v-for="(block_card, index) in block_cards" :key="index" style="color: black" >
                <div>{{block_card.name}}</div>
            </option>
          </select>
        </base-input>
        <div v-if="!model[number-1].match" class="lined_up"><img style="margin: 10px" width ="20px" src="img/check_no.png"></div>
        <div  v-if="model[number-1].match" class="lined_up"><img style="margin: 10px" width ="20px" src="img/check_yes.png"></div>
        <div   class="lined_up">{{model[number-1].message}}</div>
        <!-- <base-input  @keyup="block_card_search($event, $event.target)" v-if="card_selected(number)" type="text" label="TokenId (the # of Block Card):" placeholder="#..." v-model="model.tokenId"/> -->
        <base-input @keyup="match_card_search($event.target, number-1)" style="width: 250px" v-if="model[number-1].card_selected" 
        type="text" label="TokenId (the # of Block Card):" placeholder="#..." v-model="model[number-1].tokenId"/>
        
        </div>
        <div v-if="!bounty_status" > 
          <p style="color: red" class="lined_up"  >Bounty Not Fufilled</p> 
          <img class="lined_up"  style="margin: 10px" width ="20px" src="img/check_no.png">
      </div>
      <div v-if="bounty_status" > 
          <p class="lined_up"  style="color: green">Bounty Matched, Submit to Claim!</p>
          <img class="lined_up"  style="margin: 10px" width ="20px" src="img/check_yes.png">
          <base-button class="" @click="submitSend" type="info">Submit Claim!</base-button>
       </div>
        </div>
      </div>
        <div>

      </div>
      
   
      </card>
  </div>
</template>

<script>
import BaseButton from "./BaseButton.vue";
import BaseNav from "./BaseNav.vue";
import Card from "./Cards/Card.vue";

let globals = require("../globals.json");
const mainnet_contracts = require("../contracts_data.json");
const testnet_contracts = require("../test_contracts_data.json");


let contract_data;

if (globals.testnet) {
  // using testnet
  // console.log("using testnet");
  contract_data = testnet_contracts
}
else {
  // using mainnet
  // console.log("using mainnet");
  contract_data = mainnet_contracts
}

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export default {
  components: { Card, BaseNav, BaseButton },
  name: "BountyViewer",
  props: {
    bounty_num: {type: Number, default:0},
    bounty_info: {type: Object, default: {}},
  },
  data() {
    return { 
      bounty_status : false,
      bounty_open: false,
      reward_ammount: false,
      cards_infos : [[-1,-1,-1,-1,-1,-1],
      [-1,-1,-1,-1,-1,-1],[-1,-1,-1,-1,-1,-1],[-1,-1,-1,-1,-1,-1],[-1,-1,-1,-1,-1,-1],[-1,-1,-1,-1,-1,-1]
      ],
       model:[{
            selected: "",
            action_selected: "",
            selectedIndex: -1,
            number: "",
            tokenId: "", 
            actionIndex: -1,
            address: "",
            match: false,
            card_selected: false,
            current_load: 0,
            message: ""
        },{selected: "", action_selected: "", selectedIndex: -1, number: "", tokenId: "", actionIndex: -1, address: "", match: false, card_selected: false, current_load: 0, message: ""},
        {selected: "", action_selected: "", selectedIndex: -1, number: "", tokenId: "", actionIndex: -1, address: "", match: false, card_selecccted: false, current_load: 0, message: ""},
        {selected: "", action_selected: "", selectedIndex: -1, number: "", tokenId: "", actionIndex: -1, address: "", match: false, card_selected: false, current_load: 0, message: ""},
        {selected: "", action_selected: "", selectedIndex: -1, number: "", tokenId: "", actionIndex: -1, address: "", match: false, card_selected: false, current_load: 0, message: ""},
        {selected: "", action_selected: "", selectedIndex: -1, number: "", tokenId: "", actionIndex: -1, address: "", match: false, card_selected: false, current_load: 0, message: ""},
        {selected: "", action_selected: "", selectedIndex: -1, number: "", tokenId: "", actionIndex: -1, address: "", match: false, card_selected: false, current_load: 0, message: ""},
        {selected: "", action_selected: "", selectedIndex: -1, number: "", tokenId: "", actionIndex: -1, address: "", match: false, card_selected: false, current_load: 0, message: ""},
        ],
        block_cards:{},
    };
  },
  computed: {
  
  },
  watch: {
	'model': {
  	    handler: function(val) { console.log('changed') },
            deep: true
        },
  'bounty_status': {
  	    handler: function(val) { console.log('changed') },
            deep: true
        },
    'cards_infos': {
  	    handler: function(val) { console.log('changed') },
            deep: true
        }
},
  created: async function (){
    //
    //get attributes
     let num_cards_in_bounty = this.bounty_info.num_cards
     for(let i = 0; i< num_cards_in_bounty; i++){
       this.cards_infos[i] = this.bounty_info.cards[i].attributes
     }
    //
    this.block_cards = contract_data.block_cards
    console.log(this.bounty_info.num_cards, "the num")
    let bounty_i = await this.get_bounty_info()
    console.log('huh?',bounty_i)
    
    console.log(this.bounty_info.num_cards, "the num")
    if (bounty_i["result"] == true){
      this.bounty_open = true
      this.reward_ammount = (Number(bounty_i["info_about"][2]) / 1e18).toFixed(3)
      
    }

  },
  mounted: async function (){
   
  },
  methods: {
    get_background(number) {
      let pack_index = this.bounty_info.cards[number].pack[2]
      let ultra_number = this.cards_infos[number][2]
      let card_run_info = contract_data.block_cards[pack_index]
      let bg 

      bg =
        "img/" +
        card_run_info
          .image_folder +
        "/t/" +
        1 +
        "/" +
        ultra_number +
        "/bg.png";

      return bg;
    },
    get_border(number) {
      let pack_index = this.bounty_info.cards[number].pack[2]
      let border_num = this.cards_infos[number][4]
      let card_run_info = contract_data.block_cards[pack_index]
      let border

      border = 'img/' + card_run_info.image_folder +'/b/' + border_num + '.png'

      return border;
    },
    rare_requirement(number){
      let result;
      result = this.cards_infos[number][3]
      console.log("rare", result)
      if (result == -1){
        return false
      }
      return "Yes"
    },
    type_requirement(number){
      let result;
      let pack_index = this.bounty_info.cards[number].pack[2]
      result = this.bounty_info.cards[number].crypto
      let card_run_info = contract_data.block_cards[pack_index]
      let crypto = card_run_info.coins[result]
      // console.log("crypto", result)
      if (result == -1){
        return false
      }
      return String(crypto)
    },
    border_requirement(number){
      // let pack_index = this.bounty_info.cards[number].pack[2]
      // let card_run_info = contract_data.block_cards[pack_index]
      
      let border = this.cards_infos[number][4]
      console.log("border?",border)
      if (border == -1){
        return false
      }
  
      let final_string = String(border)
      return final_string
    },
    ultra_requirement(number){
      let pack_index = this.bounty_info.cards[number].pack[2]
      let card_run_info = contract_data.block_cards[pack_index]
      
      let ultra = this.cards_infos[number][2]
      console.log("ultra?",ultra)
      if (ultra == -1){
        return false
      }
      let result = card_run_info.ultras[ultra]
      console.log("card_run_info",card_run_info,result)
      let final_string = "Multiplier: " + result[0] + ", Out of: " + result[1]
      if (result[1] == false){
        final_string = "Multiplier: " + result[0]
      }
      return final_string
    },

    async get_bounty_info(){
      let bounty_num = Number(this.bounty_info.num_cards) - 1
      // let check_for_other_num = Number(this.bounty_info.num_cards)
      // if (check_for_other_num == 5){
      //   console.log("FOUND it", check_for_other_num)
      //   bounty_num = check_for_other_num
      // }
      let result = await this.$store.dispatch("bounty_info",
            {"bounty_address": this.bounty_info.address,"bounty_info": bounty_num}) 
      console.log("info?",this.bounty_info.address, result)
      return result
    },
   async switchIndex(event, selectedIndex, number){
    //  console.log(event, selectedIndex ,number)
    console.log(number)
    this.model[number].card_selected = true
    this.model[number].selectedIndex = selectedIndex 
    this.check_after_index_change(this.model[number].tokenId,number)
   },
   change_message(card_index,message){

     this.model[card_index].message = message.slice(42)
   },
   getlabel(number){
     return "BlockCard " + number
   },
   check_if_total_match(){
     console.log("check_if_total_match")
     let num_cards_in_bounty = this.bounty_info.num_cards
     for(let i = 0; i< num_cards_in_bounty; i++){
       if (this.model[i].match == false){
         return false
       }
     }
     return true
   },
   async check_after_index_change(tokenId, card_index){
     this.match_card_search({"value": Number(tokenId) }, card_index)
   },
    async submitSend(){
    console.log("submitSend")
    let num_cards_in_bounty = this.bounty_info.num_cards
    let address_of_cards = []
    let token_ids = []
     for(let i = 0; i< num_cards_in_bounty; i++){
       let block_card_type_address = this.block_cards[(this.model[i].selectedIndex)-1].address
       let current_token_id = this.model[i].tokenId
        address_of_cards[i] = block_card_type_address
        token_ids[i] = current_token_id
     }
     console.log(address_of_cards)
     console.log(token_ids)

     let result = await this.$store.dispatch("redeem_bounty",
            {"address_of_cards":address_of_cards,
            "bounty_address": this.bounty_info.address,
            "token_ids":token_ids}) 
    await timeout(1500)
    let bounty_i = await this.get_bounty_info()
    console.log('huh?2',bounty_i)
    
    console.log(this.bounty_info.num_cards, "the num")
    if (bounty_i["result"] == true){
      this.bounty_open = true
      this.reward_ammount = (Number(bounty_i["info_about"][2]) / 1e18).toFixed(3)
      
    }
    else{
      this.bounty_open = false
      this.reward_ammount = false

    }
    
    console.log(result)

  },
   async match_card_search(card_info, card_index){
            // alert("hi")
            console.log("match_card_search", card_index, card_info.value )
            console.log("selected index", this.model[card_index].selectedIndex)

            let block_card_type_address = this.block_cards[(this.model[card_index].selectedIndex)-1].address
            console.log("selected address",block_card_type_address)
            
            this.model[card_index].current_load +=1
            let status = this.model[card_index].current_load
            if (card_info.value == ""){
                console.log("blank")
                return
            }
            console.log("search",card_info.value)
  
            let tokenId = Number(card_info.value)
            await timeout(1500)
            if (status != this.model[card_index].current_load){
                console.log("oops")
                return
            }
            let result = await this.$store.dispatch("match_card_bounty",
            {"block_card_type_address":block_card_type_address,
            "bounty_address": this.bounty_info.address,
            "tokenId":tokenId, "card_index": card_index})
            
            // this.messages[card_index] = result["message"] 
            this.change_message(card_index,result["message"] )
            if (result["result"] == false){
                this.model[card_index].match = false 
                this.bounty_status = false
                console.log("not there",result["message"] )
                return
            }
            

            this.model[card_index].match = true 
            console.log(result, "wowza")

           this.bounty_status = this.check_if_total_match()
            

   }
  },
};
</script>
<style scoped>
.lined_up{
  display: inline-block;
}
.new_card{
    /* can be treated like a fallback */
  background-color: grey;

  /* will be "on top", if browser supports it */
  background-image: linear-gradient(to bottom right, rgb(0, 87, 0) 20%, rgb(255, 255, 255)) !important;

  /* these will reset other properties, like background-position, but it does know what you mean */
  background: green;
  background: linear-gradient(to bottom right, rgb(0, 87, 0) 20%, rgb(255, 255, 255)) !important;
}
.button_right {
  /* margin-bottom: -15px;  */
  margin-top: 10px;
  margin-left: 10%;
  margin-right: 15%;
}
.button_left {
  /* margin-bottom: -10px;  */
  margin-top: 10px;
  /* margin-left:15%; */
  margin-right: 15%;
}
.open_button {
  margin-top: 10px;

  /* margin-right:15%; */
}

.parent {
  position: relative;
  top: 0;
  left: 0;
}

.val_overlay {
  position: absolute;
  top: 10%;
  left: 10%;
  z-index: 0;
}
.val_overlay_2 {
  position: absolute;
  bottom: 10%;
  right: 10%;
  z-index: 0;
}

.image1 {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  /* border: 1px solid #000000; */
}
.image2 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  /* border: 1px solid #000000; */
}

.image3 {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  /* border: 1px solid #000000; */
}
</style>
