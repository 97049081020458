<template>
    <div>
        <!-- I am a box  {{bounty_index_number}} -->
        <div v-for="bounty in bounties" :key="bounty.id" class="col-lg-12">
            <bounty-viewer v-if="true" :bounty_info="bounty" />
        </div>
    </div>
</template>

<script>
import BlockCardViewer from '../components/block_card_viewer.vue';
import BountyViewer from '../components/bounty_viewer.vue'
import Web3 from 'web3';
import Modal from '@/components/Modal';
import Bounty_viewer from '../components/bounty_viewer.vue';
// const  { abi_nft_1 } = require("../abis.json");
const abis = require("../abis.json");
const globals = require("../globals.json");
const mainnet_contracts = require("../contracts_data.json"); 
const testnet_contracts = require("../test_contracts_data.json"); 
let abi_nft_1 = abis.nfts[0][1];
let abi_block_pack = abis.block_packs[0][1];
let abi_block_card = abis.block_cards[0][1];
// const  { nfts } = require("../contracts_data.json");

let current_nfts;
let current_block_packs ;
let current_block_cards;
let current_read_api;
let current_bounties;


if (globals.testnet){

    current_block_cards = testnet_contracts.block_cards
    current_bounties = testnet_contracts.bounties
    console.log("current_bounties", current_bounties)
    // console.log("current_block_cards", current_block_cards)
    current_read_api = globals.testnet_read_api
  }
  else{
    current_block_cards = mainnet_contracts.block_cards
    current_bounties = mainnet_contracts.bounties
    // console.log("current_block_cards", current_block_cards)
    current_read_api = globals.mainnet_read_api
  }




////////
let current_block_card_images = ["img/block_card_01_02/t/0/1/l.png"];


function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}



export default {
  components: { Modal, BountyViewer},
  name: 'BountyBox',
   data(){ 
      return {
          bounties: []

      }
      
  },
  computed:{

  },
  props: {
      bounty_index_number: { type : Number,
            default: 0 
            },
      title: {type: String, default: "BlockCards"},
      initial_show_or_not: {type: Boolean, default: true}
  },
  created: async function() {
    if (this.$store.state.bounties_updated.length == 0){
       console.log("updating bounties in state")
  
       console.log("num bounties", current_bounties.length, current_bounties[0].address)
       let num_bounties = current_bounties.length
       for (let i = 0; i < num_bounties; i++){
         this.bounties = current_bounties[i]
        //  this.$store.state.bounties_updated[this.bounty_index_number] = current_bounties[i]
       }
    }
    else {
      console.log("restorign bounties from state")
      let num_bounties = this.$store.state.bounties_updated[this.bounty_index_number].length
      for (let i = 0; i < num_bounties; i++){
         this.bounties = current_bounties[i]
         this.$store.state.bounties_updated[this.bounty_index_number] = current_bounties[i]
       }
    }
    
  },
  mounted: async function(){

    },
    methods:{
    
  

    
    }
}
</script>

<style scoped>
.parent {
  position: relative;
  top: 0;
  left: 0;
}

.image1 {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  /* border: 1px solid #000000; */
}
.image2 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  /* border: 1px solid #000000; */
}

.image3 {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  /* border: 1px solid #000000; */
}

.hollow-dots-spinner, .hollow-dots-spinner * {
      box-sizing: border-box;
    }

    .hollow-dots-spinner {
      height: 8px;
      width: calc(30px * 3);
      margin-top: 12px;
    }

    .hollow-dots-spinner .dot {
      width: 8px;
      height: 8px;
      margin: 0 calc(8px / 2);
      border: calc(8px / 5) solid #2e9e54;
      border-radius: 50%;
      float: left;
      transform: scale(0);
      animation: hollow-dots-spinner-animation 1000ms ease infinite 0ms;
    }

    .hollow-dots-spinner .dot:nth-child(1) {
      animation-delay: calc(300ms * 1);
    }

    .hollow-dots-spinner .dot:nth-child(2) {
      animation-delay: calc(300ms * 2);
    }

    .hollow-dots-spinner .dot:nth-child(3) {
      animation-delay: calc(300ms * 3);

    }

    @keyframes hollow-dots-spinner-animation {
      50% {
        transform: scale(1);
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
</style>