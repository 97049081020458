<template>
  <div>
    <!-- <card class="space" style="width: 19rem; padding: 1rem"> -->
    <kinesis-container>
      <!-- <kinesis-element  :strength="15" 
                    type="depth"
                    >  -->
      <card  v-bind:class="{ new_card: show_new_class }" >
        <div >
          <!-- <kinesis-element  :strength="20" 
                    type="depth"
                    >  -->
          <div style="display: inline-block">
            <p style="display: inline-block" class="nft">{{ name }}</p>
            <p style="display: inline-block; padding-left: 50px">
              #{{ tokenId }}
            </p>
            <p style="color: #3aa63f">Staked</p>
            <p style="color: #3aa63f; font-weight: bold"> {{card_info["dust"]}} 
              <span style="color: #cb42f5; font-weight: normal"> DUST to Unlock </span></p>
          </div>

          <br />
          <br />
          <!-- </kinesis-element> -->
          <!-- <img slot="image" class="card-img-top" src="img/eth_card.png" alt="Card image cap"/> -->
          <kinesis-element :strength="20" type="depth">
            <!-- <a :href=img_link target="1">
        <img :src=nft_img class="card-img-top"> 
        </a> -->
          </kinesis-element>
          <!-- <kinesis-element :strength="20" type="depth"> -->
            <div class="parent">
              <kinesis-element :strength="25" type="depth" class="image1" >
              <img  :src="get_logo" width="100%" />
              </kinesis-element>
              <kinesis-element :strength="15" type="depth" class="image2" >
              <img  :src="get_background" width="100%" />
              </kinesis-element>
<!-- todo cw this needs to be modular :src="'img/block_card_01_02/b/' + card_info[5] + '.png'"-->
<!-- v-if="get_border(card_info[5]) != 2" -->
<kinesis-element :strength="15" type="depth" class="image3" >
              <img v-if="get_final_border(card_info[5])" 
                
                
                :src="get_final_border(card_info[5])"
                width="100%"
              />
              </kinesis-element>
              <kinesis-element :strength="30" type="depth" class="val_overlay">
              <p
                style="
                  color: white;
                  font-size: large;
                  text-shadow: -1px 0 black, 0 1px black, 1px 0 black,
                    0 -1px black;
                "
                
              >
                {{ val_overlay }}
              </p>
              </kinesis-element>
              <kinesis-element :strength="30" type="depth" class="val_overlay_2">
              <p
                style="
                  color: white;
                  font-size: large;
                  text-shadow: -1px 0 black, 0 1px black, 1px 0 black,
                    0 -1px black;
                "
                
              >
                {{ get_special_nums(card_info[6], card_info[3]) }}
              </p>
              </kinesis-element>
              <!-- </a> -->
            </div>
          <!-- </kinesis-element> -->
          <br />

          <base-button v-if="extended"
            style="margin-left: 0; padding: 0"
            type="success"
            class="btn-link btn-neutral"
            @click="add_coin_to_wallet"
          >
            <p style="text-decoration: underline">
              Add {{ get_type(card_info[1]) }} to wallet
            </p>
          </base-button>

          <p v-if="card_info[3] != '1'" style="">
            Ultra Multiplier: {{ get_multiplier(card_info[3]) }}
          </p>
          <p v-if="card_info[4] == '1'" style="">
            Rare Common
          </p>
          <p
            v-if="card_info[3] == '1' && card_info[4] != '1'"
            style="padding-top: 4%; padding-bottom: 3%"
          ></p>
          <!-- <p>Base Rarity: {{get_base_rarity(card_info[4])}}</p> -->
          <!-- <p>Border Style: {{get_border(card_info[5])}}</p> -->

          <!-- <div v-if="mobile">  -->
          <div v-if="extended" class="row justify-content-center">
            <!-- <a :href=data_link target="blank"><p style="fontWeight: bold; color: purple">DATA</p></a> -->
            <!-- <kinesis-element  :strength="20" 
                    type="depth"
                    >   -->
            <a :href="data_link" target="2" class="">
              <base-button type="info" size="sm" class="button_left"
                >Data</base-button
              ></a
            >
            <!-- </kinesis-element >
                       <kinesis-element  :strength="20" 
                    type="depth"
                    >  -->
            <a :href="contract_link" target="3" class="">
              <base-button type="info" size="sm" class="button_right"
                >Contract</base-button
              ></a
            >
            <!-- </kinesis-element > -->
          </div>
          <div v-if="extended" class="row justify-content-center">
            <a target="blank" class="">
              <base-button
                type="danger"
                size="sm"
                class="open_button"
                @click="activate_r_b_modal"
                >Unstake</base-button
              ></a
            >
          </div>
        </div>
        <!-- </div> -->
      </card>
      <!-- </kinesis-element> -->
    </kinesis-container>
  </div>
</template>

<script>
import BaseButton from "./BaseButton.vue";
import BaseNav from "./BaseNav.vue";
import Card from "./Cards/Card.vue";

let globals = require("../globals.json");
const mainnet_contracts = require("../contracts_data.json");
const testnet_contracts = require("../test_contracts_data.json");


let contract_data;

if (globals.testnet) {
  // using testnet
  // console.log("using testnet");
  contract_data = testnet_contracts
}
else {
  // using mainnet
  // console.log("using mainnet");
  contract_data = mainnet_contracts
}

export default {
  components: { Card, BaseNav, BaseButton },
  name: "BlockCardStakeViewer",
  props: {
    name: { type: String, default: "NFT!" },
    randInt: { type: Number, default: 200 },
    card_info: { type: Object, default: {} },
    img_link: { type: String, default: "" },
    img_scr: { type: String, default: "https://picsum.photos/200/200" },
    nft_data: { type: String, default: "" },
    contract: { type: String, default: "" },
    tokenId: { type: String, default: "" },
    base_num: { type: Number, default: 0 },
    contract_address: { type: String, default: "" },
    is_new:{type: Boolean, default: false},
    index_number:{type: Number, default: -1},
    extended:{type:Boolean, default: true},
    dust_to_unlock:{type: Number, default: 0}
    
  },
  data() {
    return { source: "https://picsum.photos/200/300",
            is_new_class: false,
            times_mounted: 0 };
  },
  computed: {
    show_new_class(){
      if (this.is_new){
        return true
      }
      else{
        return false
      }
    }, 
    nft_img() {
      return this.img_scr;
    },
    nft_link() {
      return this.img_link;
    },
    data_link() {
      return this.nft_data;
    },
    contract_link() {
      if (globals.testnet) {
        return (
          "https:///testnet.bscscan.com/token/" +
          this.contract +
          "?a=" +
          this.tokenId
        );
      } else {
        return "https://bscscan.com/address/" + this.contract;
      }
    },
    // experimental
    val_overlay() {
      let num = (Number(this.card_info[2]) / 1e18).toFixed(6)
      if (num > 0.01){
        return (Number(this.card_info[2]) / 1e18).toFixed(3)
      }
      return (Number(this.card_info[2]) / 1e18).toFixed(6);
    },
    get_background() {
      let bg 
      if (this.card_info[4] == "1"){
        bg =
        "img/" +
        this.$store.state.current_contracts_data.block_cards[this.base_num]
          .image_folder +
        "/t/" +
        this.card_info[1] +
        "/" +
        this.card_info[3] +
        "/r/bg.png";
      }
      else{
      bg =
        "img/" +
        this.$store.state.current_contracts_data.block_cards[this.base_num]
          .image_folder +
        "/t/" +
        this.card_info[1] +
        "/" +
        this.card_info[3] +
        "/bg.png";
      }

      // console.log("the BG :",bg)
      return bg;
    },
    get_logo() {
      let bg =
        "img/" +
        this.$store.state.current_contracts_data.block_cards[this.base_num]
          .image_folder +
        "/t/" +
        this.card_info[1] +
        "/" +
        this.card_info[3] +
        "/l.png";
      // console.log("the BG :",bg)
      return bg;
    },
  },
  mounted: function (){
    if (this.is_new){
      // console.log("IT is new",this.card_info)
      this.is_new_class = true
    }
    // console.log("card info",this.tokenId,this.card_info)
  },
  methods: {
    activate_r_b_modal() {
      // console.log(this.base_num)
      if(this.$store.state.redeem_spin){
        return
      }
      this.$store.state.current_block_card = this.tokenId;
      this.$store.state.current_block_card_info = this.card_info;
      this.$store.state.current_block_card_base = this.base_num;
      this.$store.state.current_block_card_address = this.contract_address;
      // this.$store.state.block_card_r_b_modal1 = true
      let block_card_r_b_modal =
      "block_card_r_b_modal" + String(this.base_num);
      this.$store.commit('change_modal',{"modal":block_card_r_b_modal,"new_value": true})
      // this.$store.state[block_card_r_b_modal] = true;
    },
    get_type(num) {
      return this.$store.state.current_contracts_data["block_cards"][
        this.base_num
      ]["coins"][num];
    },
    get_multiplier(num) {
      return this.$store.state.current_contracts_data["block_cards"][
        this.base_num
      ]["ultras"][num][0];
    },
    get_border(num) {
      // console.log("border", num)
      return num;
    },
    get_final_border(num){
      // contract_data.block_cards
      // console.log("base num",this.card_info, this.base_num)
      // console.log("border", contract_data.block_cards[this.index_number])
      let border_rule = contract_data.block_cards[this.base_num].border_rule
      let count = Object.keys(border_rule).length;
      // console.log("the count", count);
      let border_type = false
      for (let i = 0; i < count; i++ ){
        let string_i = i.toString()
        // console.log("string_i", border_rule[string_i])
        // inclusive
        let rule = border_rule[string_i]
        if (num <= rule[1] && num >= rule[0]){
          border_type = i
        }
      }
      if (border_type === false){
        return false
      }
      // let first = contract_data.block_cards[this.base_num].border_rule["0"]
      // let second = contract_data.block_cards[this.base_num].border_rule["1"]
      // let third = contract_data.block_cards[this.base_num].border_rule["2"]
      // let fourth = contract_data.block_cards[this.base_num].border_rule["2"]
      // console.log("border info",contract_data.block_cards[this.base_num].border_rule)
      // this needs to be changed
       return 'img/' + contract_data.block_cards[this.base_num].image_folder +'/b/' + border_type + '.png'
      // return 'img/block_card_01_03/b/' + border_type + '.png'
      // contract_data
    },
    get_special_nums(num, ultra_num) {
      let out_of =
        this.$store.state.current_contracts_data["block_cards"][this.base_num][
          "ultras"
        ][ultra_num][1];
      if (out_of) {
        return String(Number(num) + 1) + "/" + out_of;
      } else {
        return "";
      }
    },
    async add_coin_to_wallet() {
      let bg =
        this.$store.state.current_contracts_data["block_cards"][this.base_num][
          "logo_urls"
        ][this.card_info[1]];

      let symbol = this.get_type(this.card_info[1]);
      if (globals.testnet) {
        symbol = "T" + symbol;
      }
      let address =
        this.$store.state.current_contracts_data["block_cards"][this.base_num][
          "coin_address"
        ][this.card_info[1]];
      // console.log(address);
      // return
      let result = await this.$store.dispatch("add_token_to_wallet", {
        address: address,
        logo: bg,
        symbol: symbol,
      });
    },
  },
};
</script>
<style scoped>
.new_card{
    /* can be treated like a fallback */
  background-color: grey;

  /* will be "on top", if browser supports it */
  background-image: linear-gradient(to bottom right, rgb(0, 87, 0) 20%, rgb(255, 255, 255)) !important;

  /* these will reset other properties, like background-position, but it does know what you mean */
  background: green;
  background: linear-gradient(to bottom right, rgb(0, 87, 0) 20%, rgb(255, 255, 255)) !important;
}
.button_right {
  /* margin-bottom: -15px;  */
  margin-top: 10px;
  margin-left: 10%;
  margin-right: 15%;
}
.button_left {
  /* margin-bottom: -10px;  */
  margin-top: 10px;
  /* margin-left:15%; */
  margin-right: 15%;
}
.open_button {
  margin-top: 10px;

  /* margin-right:15%; */
}

.parent {
  position: relative;
  top: 0;
  left: 0;
}

.val_overlay {
  position: absolute;
  top: 10%;
  left: 10%;
  z-index: 0;
}
.val_overlay_2 {
  position: absolute;
  bottom: 10%;
  right: 10%;
  z-index: 0;
}

.image1 {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  /* border: 1px solid #000000; */
}
.image2 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  /* border: 1px solid #000000; */
}

.image3 {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  /* border: 1px solid #000000; */
}
</style>
