<template>
    <div style="text-align: center">
        <br>
        <h1 style="">Information, Data, and FAQs </h1>
        <h2>How to to Mint a BlockPack</h2>
        <div class="responsive-youtube">
        <iframe width="50%" height="50%" src="https://www.youtube.com/embed/D4IYoS5k0YM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <br><br>
        <h2>How to Search for Bounties</h2>
        <div class="responsive-youtube">
        <iframe width="50%" height="50%" src="https://www.youtube.com/embed/YcTbpGJHDQg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <br><br>
        <img src="img/rm0.png"> 
        <img src="img/binfo0.png"> 
        
        <br><br>

        <h2>Our Contracts</h2>  
        <h3>FundaMINTals 1 2022</h3>
        <p>BlockPack:<a href="https://bscscan.com/token/0xC139C8AE611A884886601abc20565e21E433dE1F" target="_blank"> 0xC139C8AE611A884886601abc20565e21E433dE1F</a></p>
        <p>BlockCard:<a href="https://bscscan.com/token/0x31232A0B8acc51DA9B96F6a49C4B9a44dc5755A3" target="_blank"> 0x31232A0B8acc51DA9B96F6a49C4B9a44dc5755A3</a></p>
        <br><br><br><br><br><br>
        <h3>Holiday 2021</h3>
        <p>BlockPack:<a href="https://bscscan.com/token/0x30EF609d33E78d997CF96EdA02Da435F76ce6C67" target="_blank"> 0x30EF609d33E78d997CF96EdA02Da435F76ce6C67</a></p>
        <p>BlockCard:<a href="https://bscscan.com/token/0xe8926BaD20e056b16B7E0D3c4b11915Ff4c1dD65" target="_blank"> 0xe8926BaD20e056b16B7E0D3c4b11915Ff4c1dD65</a></p>
        <br><br><br><br><br><br>  
        <h3>Edition 1</h3>
        <p>BlockPack:<a href="https://bscscan.com/token/0xbcd485497d6d17f4af59ffe0e8cf403e938a29eb" target="_blank"> 0xbCD485497d6D17F4Af59fFE0e8Cf403E938a29EB</a></p>
        <p>BlockCard:<a href="https://bscscan.com/token/0x259997a971dd07222a53cf3db352a40ce16e8d22" target="_blank"> 0x259997a971dd07222a53cf3db352a40ce16e8d22</a></p>
        <br><br><br><br><br><br>

        <h2>Special Thanks to Our featured artists</h2>
        
        <p><a href="https://www.shutterstock.com/g/whitebarbie?rid=320779&utm_source=ctrbreferral-link" target="_blank">Whitebarbie</a></p>
    </div>
</template>

<script>
import ManagePacks from '../components/Inputs/ManagePacks.vue'
import ManageCards from '../components/Inputs/ManageCards.vue'
import {globalStore} from '../main.js'
const abis = require("../abis.json");
const globals = require("../globals.json");
const mainnet_contracts = require("../contracts_data.json"); 
const testnet_contracts = require("../test_contracts_data.json"); 

let abi_nft_1 = abis.nfts[0][1];
let abi_block_pack = abis.block_packs[0][1];
let abi_block_card = abis.block_cards[0][1];
// const  { nfts } = require("../contracts_data.json");


let contract_data; 

  if (globals.testnet){
    // using testnet
    // console.log("using testnet");
    contract_data = testnet_contracts
  }
  else{
    // using mainnet
    // console.log("using mainnet");
    contract_data = mainnet_contracts
  }


export default{

    components: {
        ManagePacks,
        ManageCards
    },
    data(){
        return {
        }
    },
    mounted:  function() {
        // let current_packs = contract_data.block_packs
        console.log("Info and faqs")
        
    },
    computed: {
        
    }





}
</script>
<style scoped>
.responsive-youtube
{overflow:hidden; padding-bottom:56.25%; position:relative; height:0;}
.responsive-youtube iframe
{left:0; top:0; height:100%; width:100%; position:absolute;}
</style>
