<template>
<div>   
    <br>
    <h2>BlockPacks</h2>
    <div v-if="no_packs">
    <p >No blockpacks in wallet</p>
    </div>
        <div v-if="true" ref="box" class="row the_box">
            <div v-for="(nft, index) in block_packs" :key="index" class="col-lg-6">
              <!-- <a :href="nft[1]" target="blank"> -->
                <block-pack-viewer  :img_link="nft[0]" :img_scr="nft[1]" :nft_data="nft[2]" :name="nft[3]" :contract="nft[4]"
                :number_of_block_packs="nft[5]" :pack_index="nft[7]" :order_index="index" :inverse_index="nft[8]" @open="activatemodal()"/>
              <!-- </a> -->
 
    </div>
</div>
<modal :show.sync="modal_bc" class = "nft_modal" style="">
     <template slot="header">
        <h5 class="modal-title" id="exampleModalLabel">Are you sure you want to open: <span style="font-weight: bold">{{current_pack_name}}</span> </h5>
       
     </template>
    <div class="" style="height: 40%">
        
    <div class="center"> 
     <p>This process is not reversible </p>
        <div style="" class="row justify-content-center" >
        <a v-if="!open_spin" target="blank" class=""> 
          <base-button  style="padding-right: 30px" type="danger" class="open_button" v-on:click="open_blockpack()">Open & Burn</base-button></a>
          
           <div style="" v-if="open_spin">
           <div class="self-building-square-spinner">
              <div class="square"></div>
              <div class="square"></div>
              <div class="square"></div>
              <div class="square clear"></div>
              <div class="square"></div>
              <div class="square"></div>
              <div class="square clear"></div>
              <div class="square"></div>
              <div class="square"></div>
            </div>
         </div>
        </div>
    </div>

</div>
     <template slot="footer">

     </template>
   </modal>


    </div>
</template>

<script>
import BlockPackViewer from '../components/block_pack_viewer.vue';
import Web3 from 'web3';
import Modal from '@/components/Modal';
// const  { abi_nft_1 } = require("../abis.json");
const abis = require("../abis.json");
const globals = require("../globals.json");
const mainnet_contracts = require("../contracts_data.json"); 
const testnet_contracts = require("../test_contracts_data.json"); 

let abi_nft_1 = abis.nfts[0][1];
let abi_block_pack = abis.block_packs[0][1];
let abi_block_card = abis.block_cards[0][1];
// const  { nfts } = require("../contracts_data.json");

let current_nfts;
let current_block_packs ;
let current_block_cards;
let current_read_api;

if (globals.testnet){

    current_block_packs = testnet_contracts.block_packs
    // console.log("current_block_packs", current_block_packs)
    current_read_api = globals.testnet_read_api
  }
  else{
    current_block_packs = mainnet_contracts.block_packs
    // console.log("current_block_packs", current_block_packs)
    current_read_api = globals.mainnet_read_api
  }


// let current_block_pack_images = ["img/pack1.png"]
let current_block_pack_images = ["img/bp1_small.png"]




////////
let current_nfts_images = ["img/silent_investor_small.png"];

// nfts.map((x) => {console.log(x)})
// console.log(nfts)
// for (let key in nfts) {
//     if (nfts.hasOwnProperty(key)) {
//         console.log(key + " -> " + nfts[key]["address"]);
//     }
// }

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}


const fetchJsonGetImage = async (link) =>{
  const myRequest = new Request(link);
  let data = fetch(myRequest).
  then((result) =>{
    console.log(result)
    return result.json()
  .then((result) => {
      return result
    })
    // return result
  })
  return data
}
const fetch_nft = async (i,account) => {
          // let web3_1 = new Web3(window.ethereum);
          let web3_1 = new Web3(current_read_api);
          let contract_token_1 = new web3_1.eth.Contract(abi_block_pack,current_block_packs[i].address)
          console.log(account,"THE account")
          // for the time being this need to be uncommented on mainnnet
          // let nft_balance = await contract_token_1.methods.balanceOf("0x54b3d5f6929c43bc36dfd0e73198765e32694ae8").call({from: account})
          let first_nft
          let owners_balance
          try{
                // first_nft = await contract_token_1.methods.tokenOfOwnerByIndex(account,i).call({from: account})
                first_nft = await contract_token_1.methods.tokenOfOwnerByIndex(account,0).call({from: account})
                owners_balance = await contract_token_1.methods.balanceOf(account).call({from: account})
                console.log("first index",first_nft)
          }
          catch(err){
              console.log(err)
              return false
          }
          if (first_nft){
            try{
            let nft_uri = await contract_token_1.methods.tokenURI(first_nft).call()
            console.log("token URI fetched: ", nft_uri);
            let just_hash = nft_uri.slice(7)
            // just_hash = "QmRiLuw2EL3qXaQsgfBA2udY8pwM19PRzLTjhAkVPJ9ViP"
            let link = "https://gateway.pinata.cloud/ipfs/"+ just_hash
            // let link_result = await fetchJsonGetImage(link)
            // console.log("link_result: ", link_result)
            // return ([link_result.link_image,current_block_pack_images[i],link,current_block_packs[i].name,current_block_packs[i].address,
            // owners_balance,current_block_packs[i],i]);
            return (["link_result.link_image",current_block_packs[i].image,link,current_block_packs[i].name,current_block_packs[i].address,
            owners_balance,current_block_packs[i],i,current_block_packs[i].inverse_index]);
            // return ([link_result.link_image,current_block_packs[i].image,link,current_block_packs[i].name,current_block_packs[i].address,
            // owners_balance,current_block_packs[i],current_block_packs.inverse_index]);
            }
            catch(err){
              console.log("one time",err)
              return false
            }
          }
  
}

export default {
  components: { BlockPackViewer, Modal },
  name: 'BlockPackBox',
  data(){ 
      return{
        no_packs: false,
        reload: false
               
        }
      
  },
  computed:{

    block_packs(){
      // return this.$store.state.block_packs.slice().reverse()
      // console.log("packs", this.$store.state.block_packs.slice().reverse())
      // return this.$store.state.block_packs.slice().reverse()
      return this.$store.state.block_packs
    },
    
    modal_bc: {
      get: function () {
        return this.$store.state.block_pack_open_modal
      },
      set: function (new_value) {
         this.$store.state.block_pack_open_modal = new_value
      }
    },
    current_pack_name(){
      return this.$store.state.current_block_pack
    },
    open_spin(){
      return this.$store.state.open_spin;
    }
    
  },
  props: {
      nfts1: { type : Array,
            default: () => ["https://picsum.photos/200/200",
            "https://picsum.photos/200/200","https://picsum.photos/200/200",
            "https://picsum.photos/200/200",
            "https://picsum.photos/200/200","https://picsum.photos/200/200",]
            }
  },
  created: async function() {
    console.log("NFT BOX CREATED!")
    let web3_1 = new Web3(window.ethereum);
    let accounts = await web3_1.eth.getAccounts()
    this.$store.state.account = accounts[0]
    // for (let i =0; i<3; i++){
    //     let instance = Vue.extend(NftViewer)
    //     instance.$mount()
    //     this.$refs.box.appendChild(instance.$el)
    // }
  },
  mounted: async function(){
    this.pack_function()
  },
  //   let web3_1 = new Web3(window.ethereum);
  //   let accounts = await web3_1.eth.getAccounts()
  //   this.$store.state.account = accounts[0]
  //       if (this.$store.state.block_packs.length === 0){
  //       // for (let key in nfts) {
  //       //   if (nfts.hasOwnProperty(key)) {
  //       //     console.log(key);
  //       let num_nfts = current_block_packs.length
  //       for(let i = 0; i < num_nfts; i++){
  //         let nft_call = await fetch_nft(0,this.$store.state.account);
          
  //         console.log(nft_call,"NFT call!2")
  //         // nft_call.push(current_nfts[i])
  //         if(nft_call){
  //             this.$store.state.block_packs.push(nft_call)
  //         }
  //         if (this.$store.state.block_packs.length == 0){
  //             this.no_packs = true
  //         }
  //         await timeout(200)
          
  //         }
  //       }
  // },
 
  methods:{
    async pack_function(){
    let web3_1 = new Web3(window.ethereum);
    let accounts = await web3_1.eth.getAccounts()
    this.$store.state.account = accounts[0]
    if (this.reload || this.$store.state.reload_block_packs){
      this.$store.state.block_packs = []
      this.$store.state.reload_block_packs = false
      this.reload
    }
        if (this.$store.state.block_packs.length === 0){
        // for (let key in nfts) {
        //   if (nfts.hasOwnProperty(key)) {
        //     console.log(key);
        let num_nfts = current_block_packs.length
        for(let i = 0; i < num_nfts; i++){
          let nft_call = await fetch_nft(i,this.$store.state.account);
          
          console.log(nft_call,"NFT call!2")
          // nft_call.push(current_nfts[i])
          if(nft_call){
              this.$store.state.block_packs.push(nft_call)
          }
          if (this.$store.state.block_packs.length == 0){
              this.no_packs = true
          }
          else{
            this.no_packs = false
          }
          await timeout(500)
          
          }
        }
  },

    // async activatemodal(item,nft){
    //     console.log(item, "WAT IS IT?")
    //       this.modal_bc = true;
          

    //       console.log(this.modal_info, "WAT IS IT 2?")
    //       // this.price_on_modal = ((Number(await (this.$store.dispatch('get_price',{"nft_id":item.id}))) + 1e15) / 1e18 ).toFixed(4)
    //       // let result = await this.$store.dispatch('check_approved',{"token_data": item.id, "coin_id":0})
    //       // console.log("result ISS: ", result)
    //       // this.test_aproved = false
    //   },
    filter_by(){
      
    },
      
      async open_blockpack(event,address){
      console.log("opening pack index num: ", this.$store.state.current_block_pack_index)
      // console.log(this.$store.state)
      this.$store.state.open_spin = true;
      let string_of_opening_pack = "current_block_card_opening" + String(this.$store.state.current_block_pack_index)
      // in the works for failed transaction protection
      // if(this.$store.state[string_of_opening_pack]){
      //   let current = this.$store.state[string_of_opening_pack]
      //   current += 1
      //   this.$store.commit('change_state',{"name":"string_of_opening_pack","new_value": current})
      // }
      // else{
      //   this.$store.commit('change_state',{"name":"string_of_opening_pack","new_value": 0})
      // }

      ////////
      try{
        console.log("is this right:", this.$store.state.current_block_pack_inverse_index)
        let result = await this.$store.dispatch('open_pack',{"pack_index": this.$store.state.current_block_pack_index})
        // let add_cards_result = await this.$store.dispatch("add_cards_to_array",{"index":this.$store.state.current_block_pack_index, "card_index":this.$store.state.current_block_pack_inverse_index})
        let add_cards_result = await this.$store.dispatch("add_cards_to_array",{"index":this.$store.state.current_block_pack_inverse_index})
        // console.log(result)
        this.$store.state.open_spin = false;
        this.$store.state.block_pack_open_modal = false
        // window.location.reload() // replace with update to local storage and refresh to state of appropriate block cards
      }
      catch(err){
          console.log(err)

          console.log("DINT GET THERE")
          this.$store.state.open_spin = false;
          return 
        }
        this.$store.state.open_spin = false;
        this.reload = true
        this.pack_function()
        // todo gotta update block_packs # and if none delete the field

      }
  }
}
</script>

<style >
.parent {
  position: relative;
  top: 0;
  left: 0;
}

.image1 {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  /* border: 1px solid #000000; */
}
.image2 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  /* border: 1px solid #000000; */
}

.image3 {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  /* border: 1px solid #000000; */
}
</style>