<template>
    <div>
        <br>
        <h1>Trade NFTs </h1>
        <h3>Trade Block<span>Cards</span> </h3>
        <trade-cards :block_cards="current_cards"/>

        <!-- <manage-packs class="col-md-6"/> -->
        
        <!-- <manage-cards :block_cards="current_cards"/> -->
        <!-- <manage-packs class="col-md-6"/> -->

        
        
    </div>
</template>

<script>
import TradeCards from '../components/Inputs/TradeCards.vue'
const abis = require("../abis.json");
const globals = require("../globals.json");
const mainnet_contracts = require("../contracts_data.json"); 
const testnet_contracts = require("../test_contracts_data.json"); 



let contract_data; 

  if (globals.testnet){
    // using testnet
    // console.log("using testnet");
    contract_data = testnet_contracts
  }
  else{
    // using mainnet
    // console.log("using mainnet");
    contract_data = mainnet_contracts
  }


export default{

    components: {
        TradeCards
    },
    data(){
        return {
            current_packs: contract_data.block_packs,
            current_cards: contract_data.block_cards
        }
    },
    mounted:  function() {
        // let current_packs = contract_data.block_packs
        console.log("hi", contract_data.block_packs)
        
    },
    computed: {
        pack_data(){
            let object = contract_data.block_packs
            console.log*(object)
            return contract_data.block_packs
        }
    }





}
</script>
