<template>
    <div class="row">
        <p>ELMNT Balance: {{balance}} </p>
        <card class="col-md-12" style="margin-right: 10px;">
            <div class="col-md-12">
            <form>
                <div class="form-row">
                    <div class="col-md-9 row"> 
                        <div  style="padding-top: 10px" class="col-md-6"> 
                            <p>Your Stake 
                            <span style="font-weight: bold; margin: 5px; color: #cb42f5;">
                            {{staked_dust_balance}}</span> 
                            DUST</p>
                            <base-button class="" size="sm" @click="unstakeDust" type="danger">Unstake</base-button>
                            <base-input v-if="unstaking" v-model="dust_unstake_amount" value=""/>
                        </div>
                        
                        <div style="padding-top: 10px" class="col-md-6"> 
                        <p>Rewards <span style="font-weight: bold
                        ; margin: 5px; color: #118C4F">{{elmnt_rewards}}</span> ELMNT
                        </p>
                        <base-button size="sm" class="" @click="redeemRewards" type="primary">Redeem</base-button>
                        </div>
                    </div>
                    <div class="col-md-3">
        <div v-if="!is_approved_dust">
            <p> In order to Stake you must approve your DUST For the Staking Contract </p> 
            <base-button class="" @click="approve_dust" type="info">Approve Dust</base-button> 
        </div>
        <div v-if="is_approved_dust" >
                        <p>Stake DUST</p>
                        <p> balance: {{dust_balance}} </p>
                    <base-input  v-model="model.amount" value=""/>
                    <span  @click="maxDust"  style="margin-right: 20px; cursor: pointer;">MAX</span>
                    <base-button class="" size="sm" @click="submitDustStake" type="success">Stake</base-button>
                   </div>
                    </div>
                </div> 
            </form>
            </div>
        </card>
    </div>
</template>

<script>

import {globalStore} from '../../main'
const globals = require("../../globals.json");
const mainnet_contracts = require("../../contracts_data.json"); 
const testnet_contracts = require("../../test_contracts_data.json"); 

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

let contract_data; 

  if (globals.testnet){
    // using testnet
    // console.log("using testnet");
    contract_data = testnet_contracts
  }
  else{
    // using mainnet
    // console.log("using mainnet");
    contract_data = mainnet_contracts
  }



export default{

    components: {
        
    },
    data(){
        return {
            model:{
            selected: "",
            amount: "",
        },
        dust_unstake_amount: 0,
        own_card: false,
        dust_balance: 0,
        staked_dust_balance: 0,
        is_approved_dust: false,
        elmnt_rewards: 0,
        unstaking: false,
        }
    },
    props: {
        block_cards: {}
    },
    created: function(){
        console.log("created stake dust/earn elemint")
    },
    computed:{
        balance(){
        let tokenB = BigInt(this.$store.state.elemintTokenBalance) / BigInt(10**18) ;
        return  tokenB;
        }
    }
    ,
    mounted: async function(){
        this.mounted_function()
        // await this.check_approved_dust_general();
        // console.log("mounted stake dust/earn elemint")
        // let result = await this.$store.dispatch("get_coin_balance",{"address":contract_data.dust})
        // let staked_dust_result = await this.$store.dispatch("get_dust_staked",{"address":contract_data.stakers[0].address})
        // this.get_rewards()
        // console.log(result,"dust balance")
         
        // let dust = result / 1e18
        // this.dust_balance = dust.toFixed(0) 

        // let staked_dust = staked_dust_result / 1e18
        // this.staked_dust_balance = staked_dust.toFixed(0) 
        
    },
    methods: {
        async submitStake(){
            console.log("sumit stake")
        },
         async maxDust(){
            console.log("max clicked")
            this.model.amount = this.dust_balance
        },
        async submitDustStake(){
            console.log("sumit dust stake",this.model.amount)
            
            let result = await this.$store.dispatch("dust_stake",{"amount": this.model.amount})
        },
        async check_approved_dust_general(){
            let approved_or_not = await this.$store.dispatch('check_dust_approved_general',
            {"address": contract_data.stakers[0].address })
            if (approved_or_not){
                console.log("HERE", approved_or_not)
                this.is_approved_dust = true
                
            }
            console.log("HERE", approved_or_not)
            
        },
        async approve_dust(){
            let result = await this.$store.dispatch('approve_dust_general',{"address": contract_data.stakers[0].address })
            let approved = await this.check_approved_dust_general();
        },
        async get_rewards(){
            let result = await this.$store.dispatch('get_rewards_earned',{})
            let amount = BigInt(result) / BigInt(10**18)
            this.elmnt_rewards = amount
        },


        async unstakeDust(){
            if (!this.unstaking){
                this.unstaking = true
                return
            }
            let result = await this.$store.dispatch('unstake_dust',
            {"staker_address":contract_data.stakers[0].address, "amount": this.dust_unstake_amount})
            this.mounted_function()
            this.unstaking = false
            
        },

        async redeemRewards(){
            let result = await this.$store.dispatch('redeem_rewards',{})
            this.mounted_function()
            this.$store.state.elemintTokenBalance = 
            await this.$store.dispatch('get_coin_balance',{"address": contract_data.elemint })
        },



        async mounted_function(){
        await this.check_approved_dust_general();
        console.log("mounted stake dust/earn elemint")
        let result = await this.$store.dispatch("get_coin_balance",{"address":contract_data.dust})
        let staked_dust_result = await this.$store.dispatch("get_dust_staked",{"address":contract_data.stakers[0].address})
        this.get_rewards()
        console.log(result,"dust balance")
         
        let dust = result / 1e18
        this.dust_balance = dust.toFixed(0) 

        let staked_dust = staked_dust_result / 1e18
        this.staked_dust_balance = staked_dust.toFixed(0) 

        }
        


    }
    



}
</script>
