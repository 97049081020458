<template>
<div class="row">
        <card class="col-md-6" style="margin-right: 10px">
            <form>
                <div class="form-row">
                    <base-input class="col-md-12" label="BlockPack" value="">
                            <select 
                            @change="switchIndex($event, $event.target.selectedIndex)"
                            id="inputState" class="form-control" v-model="model.selected">
                            <option disabled value="">Chose BlockPack Type</option>
                            <option v-for="(block_pack, index) in block_packs" :key="index" style="color: black" >
                               <div>{{block_pack.name}}</div>
                            </option>
                            </select>
                    </base-input>
                </div>
                <div style="padding-left: 20px">
                <p>Owned: {{block_pack_supply}}</p>
                <br>
                </div>
                <base-input type="text" label="How Many Packs to Send:" placeholder="amount" v-model="model.number"/>
                <base-input type="text" label="Reciever Address:" placeholder="0x..." v-model="model.address"/>
                <div class="form-row">
                <base-button class="" @click="submitSend" type="info">Send</base-button> <p class="col-md-6">{{result_message}}</p>
                </div>
            </form>
        </card>
        <div class="col-md-5" style="margin-left: 10px">
           
        </div>
    </div>
</template>

<script>

import {globalStore} from '../../main'

export default{

    components: {
    },
    data(){
        return {
            model:{
            selected: "",
            selectedIndex: -1,
            number: "",
            address: "", 
        },
        block_pack_supply : "",
        result_message : ""
        }
    },
    props: {
        block_packs: {}
    },
    created: function(){
        console.log("created manage packs")
    },
    mounted: function(){
        console.log("mounted manage packs")
    },
    methods: {
        async submitSend(){
            console.log("sending blockpacks",this.model.selected,this.model.number,this.model.selectedIndex, this.model.address)
            // console.log(window.ethereum.isMetaMask)
            // gotta move index down by 1 to get proper block pack index her 
            let index = Number(this.model.selectedIndex)
            let amount = Number(this.model.number)
            let to_address = this.model.address
            let result = await this.$store.dispatch("send_block_pack",{"index":index - 1, "amount": amount, "to_address":to_address })

            this.model.selected = ""
            this.model.selectedIndex = -1
            this.model.address = ""
            this.model.number = ""
            this.block_pack_supply = ""

            // this.$store.dispatch("send_block_pack",{"index":index - 1, "amount": amount, "to_address":to_address })
            // .then((result)=>{console.log("PROMO?>>",result)})
            // .then((result)=>{ console.log("PROMO?>>",result)})
            //  .then((result)=>{ console.log("PROMO?>>",result)})
            // if (result.status = true)
            // console.log("PROMO?>>",result)
            // let new_sup = await this.get_block_pack_supply(index-1)

        },
        switchIndex: async function(event, selectedIndex) {
            console.log("switched")
            console.log(selectedIndex)
            this.model.selectedIndex = selectedIndex;
            this.block_pack_supply = await this.get_block_pack_supply(selectedIndex-1)
        },
        async get_block_pack_supply(index){
            let result = await this.$store.dispatch("get_total_block_pack_supply",{"index":index})
            return result
        }
    }



}
</script>
