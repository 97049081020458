<template>
<div>   
        <div ref="box" class="row the_box">
            <h1>hello</h1>
            <div v-for="nft in nfts" :key="nft.id" class="col-lg-6">
              <!-- <a :href="nft[1]" target="blank"> -->
                <nft-viewer  :img_link="nft[0]" :img_scr="nft[1]" :nft_data="nft[2]" :name="nft[3]" :contract="nft[4]" />
              <!-- </a> -->
            </div>
        </div>
    </div>
</template>

<script>
// currently not used
import NftViewer from '../components/NftViewer.vue';
import Web3 from 'web3';
// const  { abi_nft_1 } = require("../abis.json");
const abis = require("../abis.json");
let abi_nft_1 = abis.nfts[0][1]
// const  { nfts } = require("../contracts_data.json");

let current_nfts = ["0xcf0eaeec2bb5c74072086f8b91f07066180f88f7"]
let current_nfts_images = ["img/silent_investor_small.png"]

// nfts.map((x) => {console.log(x)})
// console.log(nfts)
// for (let key in nfts) {
//     if (nfts.hasOwnProperty(key)) {
//         console.log(key + " -> " + nfts[key]["address"]);
//     }
// }

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}


const fetchJsonGetImage = async (link) =>{
  const myRequest = new Request(link);
  let data = fetch(myRequest).
  then((result) =>{
    console.log(result)
    return result.json()
  .then((result) => {
      return result
    })
    // return result
  })
  return data
}
const fetch_nft = async (i,account) => {
          let web3_1 = new Web3(window.ethereum);
          let contract_token_1 = new web3_1.eth.Contract(abi_nft_1,current_nfts[i])
          console.log(account,"THE account")
          // for the time being this need to be uncommented on mainnnet
          // let nft_balance = await contract_token_1.methods.balanceOf("0x54b3d5f6929c43bc36dfd0e73198765e32694ae8").call({from: account})
          let nft_balance = await contract_token_1.methods.balanceOf(account).call({from: account})
          console.log("total balance nft0: ", nft_balance);
          if (nft_balance > 0){
          let nft_uri = await contract_token_1.methods.tokenURI(0).call()
          console.log("token URI fetched: ", nft_uri);
          let just_hash = nft_uri.slice(7)
          let link = "https://gateway.pinata.cloud/ipfs/"+ just_hash
          let link_result = await fetchJsonGetImage(link)
          console.log("link_result: ", link_result)
          return ([link_result.link_image,current_nfts_images[i],link,link_result.name,current_nfts[i]]);
          }
          // contract_token_1.methods.balanceOf(this.$store.state.account).call({from: this.$store.state.account})
          // .then((result) => {console.log("total balance of NFT0 : ", result);
          //                   return result})
          //                   .then((nft_balance) => {
          //                     // this.$store.state.elemintTokenBalance = token_balance;
          //                     if (nft_balance > 0){
          //                       contract_token_1.methods.tokenURI(0).call()
          //                       .then((result) =>{
          //                         // console.log("SLICING",result.slice(7));
          //                         let just_hash = result.slice(7)
          //                         let link = "https://gateway.pinata.cloud/ipfs/"+ just_hash
          //                         fetchJsonGetImage(link)
          //                         .then((result) => {
          //                           console.log("FINALL",result)
          //                           // this.$store.state.nfts.push([result.link_image,link]);
          //                           current_nfts_images
          //                           this.$store.state.nfts.push([result.link_image,current_nfts_images[0],link,result.name]);
          //                           console.log("WOWOWO",this.$store.state.nfts)
          //                         })
          //                       })
          //                     }
          //                   }) 
}

export default {
  components: { NftViewer },
  name: 'NftBox_01',
  computed:{

    nfts(){
      return this.$store.state.nfts
    }
  },
  props: {
      nfts1: { type : Array,
            default: () => ["https://picsum.photos/200/200",
            "https://picsum.photos/200/200","https://picsum.photos/200/200",
            "https://picsum.photos/200/200",
            "https://picsum.photos/200/200","https://picsum.photos/200/200",]
            }
  },
  created: async function() {
    console.log("NFT BOX CREATED!")
    let web3_1 = new Web3(window.ethereum);
    let accounts = await web3_1.eth.getAccounts()
    this.$store.state.account = accounts[0]
    // for (let i =0; i<3; i++){
    //     let instance = Vue.extend(NftViewer)
    //     instance.$mount()
    //     this.$refs.box.appendChild(instance.$el)
    // }
  },
  mounted: async function(){
    let web3_1 = new Web3(window.ethereum);
    let accounts = await web3_1.eth.getAccounts()
    this.$store.state.account = accounts[0]
        if (this.$store.state.nfts.length === 0){
        // for (let key in nfts) {
        //   if (nfts.hasOwnProperty(key)) {
        //     console.log(key);
        let num_nfts = current_nfts.length
        for(let i = 0; i < num_nfts; i++){
          
          let nft_call = await fetch_nft(0,this.$store.state.account);
          
          console.log(nft_call,"NFT call!1")
          // nft_call.push(current_nfts[i])
          this.$store.state.nfts.push(nft_call)

          await timeout(200)
          
        }
        // let nft_call = await fetch_nft(0,this.$store.state.account);
        //   console.log(nft_call)
        //   this.$store.state.nfts.push(nft_call)




          // if window.localStorage(silent_investor_0)
          // find nfts
          // console.log(abi_nft_1)
          // let web3_1 = new Web3("https://apis-sj.ankr.com/327ad16006024d3ea2a2206b66ecc462/10707bea3215810fb4fd4ae012380bc3/binance/full/main");
          // let web3_1 = new Web3(window.ethereum);
          // let contract_token_1 = new web3_1.eth.Contract(abi_nft_1,current_nfts[0])
          // contract_token_1.methods.balanceOf("0x54b3d5f6929c43bc36dfd0e73198765e32694ae8").call({from: this.$store.state.account})
          // // contract_token_1.methods.balanceOf(this.$store.state.account).call({from: this.$store.state.account})
          // .then((result) => {console.log("total balance of NFT0 : ", result);
          //                   return result})
          //                   .then((nft_balance) => {
          //                     // this.$store.state.elemintTokenBalance = token_balance;
          //                     if (nft_balance > 0){
          //                       contract_token_1.methods.tokenURI(0).call()
          //                       .then((result) =>{
          //                         // console.log("SLICING",result.slice(7));
          //                         let just_hash = result.slice(7)
          //                         let link = "https://gateway.pinata.cloud/ipfs/"+ just_hash
          //                         fetchJsonGetImage(link)
          //                         .then((result) => {
          //                           console.log("FINALL",result)
          //                           // this.$store.state.nfts.push([result.link_image,link]);
          //                           current_nfts_images
          //                           this.$store.state.nfts.push([result.link_image,current_nfts_images[0],link,result.name]);
          //                           console.log("WOWOWO",this.$store.state.nfts)
          //                         })
          //                       })
          //                     }
          //                   }) // end
                          }
                        }
}
</script>

    